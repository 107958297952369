// extracted by mini-css-extract-plugin
export var root = "PlasmicHomepage-module--root--1l9Yi";
export var box___6FbCg = "PlasmicHomepage-module--box___6FbCg--SJ6ue";
export var box__mNvbX = "PlasmicHomepage-module--box__mNvbX--1jq_F";
export var box__saD84 = "PlasmicHomepage-module--box__saD84--Z7I7Q";
export var box__aMq = "PlasmicHomepage-module--box__aMq--1pRER";
export var box__oDgC = "PlasmicHomepage-module--box__oDgC--1fEk0";
export var box__xCbLk = "PlasmicHomepage-module--box__xCbLk--2_Uk1";
export var box__eMma = "PlasmicHomepage-module--box__eMma--IJBb5";
export var box__krQpa = "PlasmicHomepage-module--box__krQpa--3qO1O";
export var box__qaLQy = "PlasmicHomepage-module--box__qaLQy--3fktz";
export var box__gjYc = "PlasmicHomepage-module--box__gjYc--3GM6f";
export var box__ukwPd = "PlasmicHomepage-module--box__ukwPd--3Q2l7";
export var copyableLink = "PlasmicHomepage-module--copyableLink--1YaYX";
export var box__mutaK = "PlasmicHomepage-module--box__mutaK--2aS02";
export var box__mqAb = "PlasmicHomepage-module--box__mqAb--nxF6U";
export var socialIcon__ic20D = "PlasmicHomepage-module--socialIcon__ic20D--2WMTo";
export var svg__xgPbu = "PlasmicHomepage-module--svg__xgPbu--D8LRA";
export var socialIcon__jfO2 = "PlasmicHomepage-module--socialIcon__jfO2--2efbQ";
export var svg__xIpH = "PlasmicHomepage-module--svg__xIpH--2rcvK";
export var socialIcon__fj5Ud = "PlasmicHomepage-module--socialIcon__fj5Ud--WIsIb";
export var svg__xLQsR = "PlasmicHomepage-module--svg__xLQsR--2ukWz";
export var box__ozh4D = "PlasmicHomepage-module--box__ozh4D--3c_8-";